<template>
  <v-container fluid>
    <div class="d-flex mb-4">
      <h2>Manage Product Variants</h2>
      <v-spacer></v-spacer>
      <v-btn color="success" class="mr-4" @click.native="createProductType()">
        New Product Variant
      </v-btn>
    </div>
    <v-data-table
      :sort-by="['productTypeCode', 'sortOrder']"
      @click:row="editProductTypeVariant"
      :items="productTypeVariants"
      :headers="headers"
      :disable-pagination="true"
      :hide-default-footer=true
      dense
    >
      <template v-slot:[`item.unitPrice`]="{ item }">
        <span class="mx-2">{{ item.unitPrice && `£${item.unitPrice.toFixed(2)}` }}</span>
      </template>
      <template v-slot:[`item.customPrice`]="{ item }">
        <span class="mx-2">{{ item.customPrice && `£${item.customPrice.toFixed(2)}` }}</span>
      </template>
    </v-data-table>
    <product-type-variant-dialog v-if="openDialog" v-model='openDialog' :variants="productTypeVariants" :edit="edit" :data="currentProductTypeVariant" @close="closeDialog" />
  </v-container>
</template>

<script>
import ProductTypeVariantDialog from './components/ProductTypeVariantDialog'
import { mapState } from 'vuex'

export default {
  components: {
    ProductTypeVariantDialog
  },
  data() {
    return {
      openDialog: false,
      headers: [
        { text: 'Product Code', value: 'productTypeCode' },
        { text: 'Variant Code', value: 'productTypeVariantCode' },
        { text: 'Unit Price', value: 'unitPrice' },
        { text: 'Custom Price', value: 'customPrice' },
        { text: 'Title', value: 'title' },
        { text: 'Width', value: 'width' },
        { text: 'Height', value: 'height' },
        { text: 'Units', value: 'units' },
        { text: 'Sort Order', value: 'sortOrder' },
      ],
      selectedFile: null,
      validData: false,
      valid: true,
      processing: false,
      progress: 0,
      uploadSuccess: false,
      edit: false
    }
  },
  async created() {
  },
  mounted: function () {
  },
  computed: mapState({
    productTypeVariants: (state) => state.products.productTypeVariants,
  }),
  watch: {},
  methods: {
    createProductType() {
      this.edit = false
      this.currentProductTypeVariant = {
        productTypeCode: '',
        height: '',
        width: ''
      }
      this.openDialog = true
    },
    editProductTypeVariant(evt) {
      this.edit = true
      this.currentProductTypeVariant = evt
      this.openDialog = true
    },
    closeDialog(element) {
      this.openDialog = false
    }
  }
}
</script>
